table.transaction-list .amount,
table.transaction-list .balance,
table.transaction-entry-list .amount,
table.transaction-entry-list .balance {
  width: 105px;
  text-align: right;
}
table.transaction-list .processedAt,
table.transaction-list .preparedAt {
  width: 180px;
}
table.transaction-list .ratio {
  width: 50px;
  text-align: right;
}
