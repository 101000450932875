table.statement .date {
  width: 118px;
}
table.statement .key {
  width: 118px;
}
table.statement .debit,
table.statement .credit,
table.statement .balance {
  width: 110px;
}
