.panel-register {
  max-width: 820px;
  padding: 15px;
  margin: 0 auto;
}
.panel-register .form-actions {
  text-align: right;
}
#gtcPopup .modal-dialog {
  width: 75%;
}
